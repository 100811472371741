import React from "react";
import { Link } from "react-router-dom";
import{ Row, Col, Table } from "reactstrap";

import HPXInlineImg from 'assets/img/navbar/PURE/Inline/PURE-LM-Series-800-HPX/Series-800-HPX-Inline.png';
import HPNInlineImg from 'assets/img/navbar/PURE/Inline/PURE-RB-Series-800-HPN/Series-800-HPN-Inline.png';

import '../../MobileSideNavbar.css';
import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Pure({mobileNavbarState}){
  const product_data = [
    {
      title: 'PUREGEN-LM',
      link: 'pure-lm',
      description: 'EPI’s High Purity Flow Meter with an Explosion-Proof Rated Enclosure',
      type: 1,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InlineIcon,
      image: HPXInlineImg,
      default_image: HPXInlineImg,
      left_image: HPXInlineImg,
      right_image: HPXInlineImg
    },
    {
      title: 'PUREGEN-RB',
      link: 'pure-rb',
      description: 'EPI’s High Purity Flow Meter with a NEMA 4X Rated (IP66) Enclosure',
      type: 1,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InlineIcon,
      image: HPNInlineImg,
      default_image: HPNInlineImg,
      left_image: HPNInlineImg,
      right_image: HPNInlineImg
    }
  ]

  const link_dir_inline = '/products/pure/inline/';
  const link_dir_insertion = '/products/pure/insertion/';

  return(
    <>
      <div id="pure" class="sidenav" style={{'padding-top': '50px'}}>
      <Table className="table-shopping" responsive>
        <tbody>
          { product_data.map((value) => {
            return(
              <tr>
                <td>
                  <div className="contentM text-center" style={{'width': '270px'}}>
                    <img style={{'width': '270px', 'height': 'auto'}} alt="..." src={value.image} ></img>
                    <p className="nav-product-title">{ value.title }</p>
                    <p className="nav-product-description">{ value.description }</p>
                    <Row>
                      <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_inline + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Inline
                      </Col>

                  {/*    <Col
                        style={{'font-weight': '600', 'color': '#095CF6'}}
                        tag={Link}
                        to={link_dir_insertion + value.link}
                        onClick={() => mobileNavbarState()}
                      >
                        Insertion
                      </Col>  */}
                    </Row>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </>
  )
}

export default Pure;
